import React, { useEffect, useState } from "react";
import { CubitTableColumn } from "shared-components/src/cubit-table/cubit-table.types";
import CubitTable from "shared-components/src/cubit-table/cubit-table";
import { tr } from "utils/translations/translate";
import { toShortDate } from "utils/time/timeUtil";
import Spinner from "common/spinner";
import {
    makeStyles,
    Theme
  } from "@material-ui/core";
import { InvoiceFile } from "models/invoice-file";
import { FILE_NAME, FILE_TYPE, ORDER_COUNT, ORDER_FROM, ORDER_SUM, ORDER_TO, SENT_DATE } from "./translations/keys";
import DashboardClient from "clients/dashboard-client";

type FileTableProps = {
    fileList: InvoiceFile[];
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: "3rem",
    }
 }));

export const InvoiceFileTable: React.FC<FileTableProps> = ({ fileList }) => {

    const styles = useStyles();
    const [isFileLoading, setIsFileLoading] = useState(false);
    const columns: CubitTableColumn[] = [
        {
            headerLabel: tr(FILE_NAME),
            key: 'fileName',
        },
        {
            headerLabel: tr(FILE_TYPE),
            key: 'fileType',
            getDisplayableElement: (row: InvoiceFile) => (<span>{tr(`${FILE_TYPE}_${row.type}`)}</span>) 
        },
        {
            headerLabel: tr(SENT_DATE),
            key: 'sentDate',
            getDisplayableElement: (row: InvoiceFile) => (<span>{toShortDate(row.sentDate)}</span>) 
        },
        {
            headerLabel: tr(ORDER_FROM),
            key: 'orderFrom'
        },
        {
            headerLabel: tr(ORDER_TO),
            key: 'orderTo'
        },
        {
            headerLabel: (ORDER_SUM),
            key: 'sum',
            getDisplayableElement: (row: InvoiceFile) => (<span>{row.sum.toLocaleString('en-US', {minimumFractionDigits: 2})} kr</span>) 
        },
        {
            headerLabel: tr(ORDER_COUNT),
            key: 'orderCount'
        }
    ];



    const handleRowClick = async (row: InvoiceFile) => {
        setIsFileLoading(true);
        const resp = await DashboardClient.getInvoiceFile(row.type, row.id);
        saveAs(resp , row.fileName);
        setIsFileLoading(false);
    };

    return (isFileLoading ? 
        (<>
            <div className={styles.container}>
                <Spinner/>
            </div>
        </>) :
        (<CubitTable
            name="invoiceFiles"
            columns={columns}
            data={fileList}
            sidePadding="24"
            sorting={{ direction: "desc", by: "sentDate" }}
            onRowClick={handleRowClick}
            paging={{ rowsPerPage: [5] }}
        />)
    );
}